/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/styles/global.css';
import './src/styles/metadata.css';
import './src/styles/custom-styles.css';
import './src/styles/search-styles.css';
import './src/styles/homepage-styles.css';

// Universal Viewer
import './node_modules/universalviewer/dist/esm/index.css';

// Cookie Banner
import './src/styles/cookie-banner.css';

// Top Banner
import './src/styles/top-banner.css';

// Add external stylesheets
export const onClientEntry = () => {
	// Load Typekit stylesheet
	const typekitLink = document.createElement('link');
	typekitLink.rel = 'preload';
    typekitLink.as = 'style';
    typekitLink.onload = () => typekitLink.rel = 'stylesheet';
	typekitLink.href = 'https://use.typekit.net/dvj8rpp.css';
	document.head.append(typekitLink);

	// Load UoY main stylesheets
	const globalStyles = document.createElement('link');
	globalStyles.rel = 'preload';
    globalStyles.as = 'style';
    globalStyles.onload = () => globalStyles.rel = 'stylesheet';
	globalStyles.href = 'https://www.york.ac.uk/static/stable/css/styles.min.css';
	globalStyles.type = 'text/css';
	globalStyles.media = 'screen';
	document.head.append(globalStyles);

	const printStyles = document.createElement('link');
	printStyles.rel = 'stylesheet';
	printStyles.href = 'https://www.york.ac.uk/static/stable/css/print.min.css';
	printStyles.media = 'print';
	document.head.append(printStyles);
};
